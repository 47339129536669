<template>
  <div>
    <MainLayout />
  </div>
</template>

<script>
import MainLayout from './components/MainLayout.vue';
export default {
  components: {
    MainLayout,
  },
  
};
</script>